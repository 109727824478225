import { createRouter, createWebHistory } from 'vue-router'

// 연결할 각 컴포넌트 import (src/views폴더 아래 컴포넌트들 생성해둠)
import Home from "@/views/Home";
import Portfolio from "@/views/Portfolio";
import Contact from "@/views/Contact";
import Learning from "@/views/solution/Learning";
import Chat from "@/views/solution/Chat";
import Emr from "@/views/solution/Emr";
import Menu from "@/views/solution/Menu";
import Live from "@/views/solution/Live";
import Delivery from "@/views/solution/Delivery";
import Build from "@/views/service/Build";
import Operate from "@/views/service/Operate";

// 라우터 설계
const routes = [
    { path: '/', component: Home, name: "Home", redirect: '/solution/learning' },
    // { path: '/about_us', component: AboutUs, name: "About Us" },
    // { path: '/service', component: Services, name: "Services" },
    { path: '/portfolio', component: Portfolio, name: "Portfolio" },
    // { path: '/notice', component: Notice, name: "Notice" },
    // { path: '/notice/detail', component: NoticeDetail, name: "Notice Detail" },
    { path: '/contact', component: Contact, name: "Contact" },
    { path: '/solution/learning', component: Learning, name: "GO Learning" },
    { path: '/solution/chat', component: Chat, name: "GO Chat" },
    { path: '/solution/emr', component: Emr, name: "GO Emr" },
    { path: '/solution/menu', component: Menu, name: "GO Menu" },
    { path: '/solution/live', component: Live, name: "GO Live" },
    { path: '/solution/delivery', component: Delivery, name: "GO Delivery" },
    { path: '/service/build', component: Build, name: "서비스 구축" },
    { path: '/service/operate', component: Operate, name: "유지 보수" },
]

// 라우터 생성
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

// 라우터 추출 (main.js에서 import)
export default router
