<template>
  <div class="offcanvas-panel" :class="{ 'panel-on': panelOn }">
    <div class="panel-overlay" @click="closePanel"></div>
    <div class="offcanvas-panel-inner">
      <div class="panel-logo">
        <router-link to="/"><img src="/images/company/logo.png" alt="Qempo"></router-link>
      </div>
      <div class="about-us">
        <h5 class="panel-widget-title">About Us</h5>
        <p v-html="contents"></p>
      </div>
      <div class="contact-us">
        <h5 class="panel-widget-title">Contact Us</h5>
        <ul>
          <li>
            <i class="fal fa-map-marker-alt"></i>
            {{ companyInfo.addr }}
          </li>
          <li>
            <i class="fal fa-envelope-open"></i>
            <a :href="`mailto:${companyInfo.email}`">{{ companyInfo.email }}</a>
          </li>
          <li>
            <i class="fal fa-phone"></i>
            <a :href="`tel:${companyInfo.tel}`">{{ companyInfo.tel }}</a>
          </li>
        </ul>
      </div>
      <a href="javascript:;" class="panel-close" @click="closePanel"><i class="fal fa-times"></i></a>
    </div>
  </div>
</template>

<script>
import {onMounted, ref, toRefs} from "vue";
import axios from "axios";

export default {
  name: "SideBar",
  components: {

  },
  props: {
    panelOn: {
      type: Boolean,
      default: false
    },
  },
  emits: ['closePanel'],
  setup(props, {emit}) {
    const companyInfo = ref({
      addr: '',
      email: '',
      tel: ''
    })
    let contents = ref('')

    const closePanel = () => {
       emit('closePanel');
    };

    axios.get('/api/common/getAboutUs').then((res) => {
      contents.value = res.data.view.fdintro;
      companyInfo.value.addr = res.data.view.fdlocation;
      companyInfo.value.email = res.data.view.fdemail;
      companyInfo.value.tel = res.data.view.fdphone;
    })

    return {
      closePanel,
      contents,
      companyInfo,
    };
  },
}
</script>

<style scoped>

</style>
