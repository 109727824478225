<template>
  <!--====== Start Service Section ======-->
  <section class="service-area service-area-v1 bototm-minus-200 position-relative z-2 pt-210 pb-210">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="section-title text-center mb-55 wow fadeInUp">
            <span class="sub-title st-one">Services</span>
            <h5>동물병원의 EMR(Electronic Medical Record) 솔루션과 각종 의료 서비스 지원 솔루션으로 수의사와 반려동물, 반려동물의 보호자가 원활히 소통할 수 있도록 돕는 솔루션입니다.</h5>
            <!--<p>쉽고 편하게 운영 가능한 온라인 교육 플랫폼. 다양한 LMS 구축 노하우를 바탕으로 최적의 솔루션을 제공합니다.</p>-->
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".4s">
            <div class="icon">
              <i class="fi fi-rr-dog"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">동물병원을 위한 맞춤 솔루션
              </a></h5>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".3s">
            <div class="icon">
              <i class="fi fi-rr-bolt-auto"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">동물병원 운영 자동화
              </a></h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".2s">
            <div class="icon">
              <i class="fi fi-rr-display-medical"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">간편한 예약과 신속한 진료</a></h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".4s">
            <div class="icon">
              <i class="fi fi-rr-productivity"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">어디서나 손쉽고 자유로운 진료환경
              </a></h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".2s">
            <div class="icon">
              <i class="fi fi-rr-microchip-ai"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">AI 분석을 통한 자동 메시지 발송
              </a></h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="button-box text-center mt-30 wow fadeInUp">
          <a href="http://universe.solamt.com/front/main/index.do" target="_blank" class="main-btn arrow-btn bordered-btn btn-blue">GD Online EMR Demo Site</a>
        </div>
      </div>
    </div>
  </section><!--====== End Service Section ======-->
</template>

<script>
export default {
  name: "Emr",
  components: {
  },

  setup() {

    return {
    }
  }
}
</script>

<style scoped>

</style>
