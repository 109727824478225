const scrollUtil = () => {
    let level = 0;
    let scrolling = false;

    const fadeIn = (target) => {
        if (scrolling || level >= 1) return;
        scrolling = true;
        let inTimer;
        inTimer = setInterval(function () {
            level = fadeInAction(target, level, inTimer)
        }, 50);
    }

    const fadeInAction = (target, level, inTimer) => {
        level = level + 0.2;
        changeOpacity(target, level);
        target.style.display = "inline-block"
        if (level >= 1) {
            clearInterval(inTimer);
            scrolling = false;
        }
        return level;
    }

    const fadeOut = (target) => {
        if (scrolling || level <= 0) return;
        scrolling = true;
        let outTimer;
        outTimer = setInterval(function () {
            level = fadeOutAction(target, level, outTimer);
        }, 50);
    }

    const fadeOutAction = (target, level, outTimer) => {
        level = level - 0.2;
        changeOpacity(target, level);
        if (level <= 0) {
            clearInterval(outTimer);
            target.style.display = "none"
            scrolling = false;
        }
        return level;
    }

    const changeOpacity = (target, level) => {
        let obj = target;
        obj.style.opacity = level;
        obj.style.MozOpacity = level;
        obj.style.KhtmlOpacity = level;
        obj.style.MsFilter = "'progid: DXImageTransform.Microsoft.Alpha(Opacity=" + (level * 100) + ")'";
        obj.style.filter = "alpha(opacity=" + (level * 100) + ");";
    }

    const scrollTop = () => {
        window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
    }

    return {
        fadeIn,
        fadeOut,
        scrollTop,
    };
};
export default scrollUtil;
