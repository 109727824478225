<template>
  <PageBanner :pageInfo="pageInfo"></PageBanner>

  <section class="about-area about-area-v1 position-relative pt-130">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="img-holder-box mb-50">
            <div class="img-holder wow fadeInLeft">
              <img src="/images/company/GettyImages-jv12084102.jpg" alt="Image"><!--500*712-->
            </div>
            <div class="shape shape-one"><span class="animate-float-y"><img src="/images/shape/circle-logo-2.png" class="circle-logo" alt="circle logo"></span></div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="text-wrapper mb-50 wow fadeInRight">
            <div class="section-title mb-15">
              <span class="sub-title st-one">About Us</span>
              <h2>Best Digital Solutiuon Provider Agency</h2>
            </div>
            <h4>Web & Mobile app development bring your ideas to life</h4>
            <ul class="list-style-one mb-35">
              <li><span style="color: darkred">G</span>orgeous ability people</li>
              <li><span style="color: darkred">O</span>nly You</li>
              <li><span style="color: darkred">D</span>ifferent Think</li>
              <li><span style="color: darkred">U</span>nlimited Dream</li>
              <li><span style="color: darkred">K</span>nowhow & Knowledge</li>
            </ul>
            <router-link :to="'/about_us'" class="main-btn bordered-btn btn-blue arrow-btn">Learn More Us</router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--====== End About Section ======-->
  <!--====== Start Service Section ======-->
  <section class="service-area pt-90 pb-80">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="section-title text-center mb-55 wow fadeInUp">
            <span class="sub-title st-one">Services</span>
            <h2>Creative Development Solutions</h2>
            <p>Professional Development Agency to provide solutions</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-one mb-40 wow fadeInDown" data-wow-delay=".7s">
            <div class="icon">
              <i class="flaticon-source-code"></i>
            </div>
            <div class="text">
              <h3 class="title"><a href="/service">Web Development</a></h3>
              <ul class="list-style-two">
                <li>Various Devices</li>
                <li>Responsive Website</li>
                <li>Systematic Management</li>
              </ul>
              <router-link :to="'/service'" class="btn-link">Read More</router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-one mb-40 wow fadeInDown" data-wow-delay=".3s">
            <div class="icon">
              <i class="flaticon-strategy"></i>
            </div>
            <div class="text">
              <h3 class="title"><a href="/service">APP Development</a></h3>
              <ul class="list-style-two">
                <li>Native </li>
                <li>Hybrid </li>
                <li>Quality Control System</li>
              </ul>
              <router-link :to="'/service'" class="btn-link">Read More</router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-one mb-40 wow fadeInDown" data-wow-delay=".5s">
            <div class="icon">
              <i class="flaticon-design"></i>
            </div>
            <div class="text">
              <h3 class="title"><a href="/service">Integrated Operation</a></h3>
              <ul class="list-style-two">
                <li>Organization Specializing</li>
                <li>Smooth Communication</li>
                <li>Continuous Consulting</li>
              </ul>
              <router-link :to="'/service'" class="btn-link">Read More</router-link>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section><!--====== End Service Section ======-->

  <!--====== Start Portfolio Section ======-->
  <section class="portfolio-area portfolio-area-v1 light-gray-bg pt-130 pb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="section-title mb-45 wow fadeInUp">
            <span class="sub-title st-one">Latest Work</span>
            <h2>Professional Experience</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="portfolio-filter-button mb-50 wow fadeInLeft">
            <ul class="filter-btn mb-20">
              <li
                  class=""
                  :class="{ active: item.isActive }"
                  v-for="(item, index) in data"
                  :key="index"
                  @click="selectCate($event, index, item.pk_code)"
              >{{ item.fd_en_nm }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row portfolio-grid">
        <div class="col-lg-4 col-md-6 col-sm-12 cat-1 portfolio-column cat-3" :class="item.fkcatenmen" v-for="(item, index) in resultList" :key="index">
          <div class="portfolio-item portfolio-style-one mb-55 wow fadeInUp" data-wow-delay=".1s">
            <img-holder :img-src="baseURL + item.fdthimgpath" class="img-holder">
            </img-holder>
            <div class="text">
              <h3 class="title">{{ item.fdtitle }}</h3>
              <p class="cat-link">{{ item.fdsubtitle }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="button-box text-center wow fadeInUp">
            <router-link :to="'/portfolio'" class="main-btn arrow-btn">View All Projects</router-link>
          </div>
        </div>
      </div>
    </div>
  </section><!--====== End Portfolio Section ======-->

  <!--====== Start CTA Section ======-->
  <section class="cta-area cta-area-v1 pt-130">
    <div class="container-1450">
      <div class="cta-wrapper dark-blue-bg">
        <div class="cta-img wow fadeInDown "><img src="/images/company/telework-6795505_643.jpg" alt=""></div><!--643*303-->
        <div class="row">
          <div class="col-xl-8 col-lg-12">
            <div class="text-wrapper wow fadeInUp">
              <div class="section-title section-title-white mb-55">
                <span class="sub-title st-one">Let’s Work</span>
                <h2>Experience & innovative <span class="fill-text">solutions </span> for development <span class="fill-text">agency</span></h2>
              </div>
              <router-link :to="'/contact'" class="main-btn bordered-btn btn-white arrow-btn">Let's Work Together</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--====== End CTA Section ======-->
  <!--====== Start Counter Section ======-->
  <section class="counter-area counter-area-v1 pt-240 pb-90">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="section-title text-center mb-55 wow fadeInUp">
            <span class="sub-title st-one">Agency Statistics</span>
            <h2>Why People’s Like Us</h2>
            <p>Professional Design Agency to provide solutions</p>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="counter-item mb-40 wow fadeInUp" data-wow-delay=".2s">
            <div class="icon">
              <i class="flaticon-start-up"></i>
            </div>
            <div class="text">
              <h2 class="number"><span>{{cnt.fdweb}}</span>+</h2>
              <p>Web Project Complate</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="counter-item mb-40 wow fadeInUp" data-wow-delay=".3s">
            <div class="icon">
              <i class="flaticon-creativity"></i>
            </div>
            <div class="text">
              <h2 class="number"><span>{{cnt.fdapp}}</span>+</h2>
              <p>App Project Complate</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="counter-item mb-40 wow fadeInUp" data-wow-delay=".4s">
            <div class="icon">
              <i class="flaticon-medal"></i>
            </div>
            <div class="text">
              <h2 class="number"><span>{{cnt.fdaward}}</span>+</h2>
              <p>Awards Winning</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="counter-item mb-40 wow fadeInUp" data-wow-delay=".5s">
            <div class="icon">
              <i class="flaticon-technical-support"></i>
            </div>
            <div class="text">
              <h2 class="number"><span>{{cnt.fdexperience}}</span>+</h2>
              <p>Years Of Experience</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section><!--====== End Counter Section ======-->
  <!--====== Start Contact Section ======-->
  <section class="contact-area contact-area-v1 pt-70 pb-80">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-8">
          <div class="text-wrapper mb-50 wow fadeInLeft">
            <h2>Have Any on <span class="fill-text">Project</span>
              Mind! <span class="fill-text">Contact</span> Us</h2>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="information-style-one mb-40">
                  <div class="icon">
                    <span><i class="far fa-envelope-open"></i>Email Us</span>
                  </div>
                  <div class="info">
                    <h4><a href="mailto:help@g-oduk.com">{{contents.fdemail}}</a></h4>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="information-style-one mb-40">
                  <div class="icon">
                    <span><i class="far fa-phone"></i>Phone Us</span>
                  </div>
                  <div class="info">
                    <h4><a href="tel:070-3344-0099">{{contents.fdphone}}</a></h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="contact-form">
              <form>
                <div class="row">
                  <div class="col-lg-4">
                    <div class="form_group">
                      <input type="text" class="form_control" placeholder="Full Name" id="name" name="name" v-model="vname" maxlength="8">
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form_group">
                      <input type="email" class="form_control" placeholder="Email Address " id="email" name="email" v-model="vemail"  maxlength="50">
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form_group">
                      <input type="text" class="form_control" placeholder="Phone Number" id="phone" v-model="vphone" name="phone">
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form_group">
                      <textarea class="form_control" placeholder="Write Message" id="message" v-model="vmessage"  name="message"></textarea>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form_group text-center">
                      <button class="main-btn" @click.prevent="sendMessage">Send Us Message</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="img-holder mb-50 wow fadeInRight">
            <div class="shape shape-icon-one scene"><span data-depth="1"><img src="/images/shape/shape-4.png" alt=""></span></div>
            <div class="shape shape-icon-two"><span></span></div>
            <img src="/images/company/face-67430_370.jpg" alt="Contact Image"><!--370*590-->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--====== End Contact Section ======-->
  <!--====== Start Blog Section ======-->
  <section class="blog-area blog-area-v1 light-gray-bg pt-130 pb-130">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="section-title text-center mb-55 wow fadeInUp" data-wow-delay=".2s">
            <span class="sub-title st-one">Articles News</span>
            <h2>Latest News</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12" v-for="(item, index) in noticeList" :key="index">
          <div class="blog-post-item mb-40 wow fadeInUp" data-wow-delay=".25s">
            <div class="entry-content">
              <a href="javascript:;" class="cat-btn">Notice</a>
              <h3 class="title"><a href="javascript:;">{{ item.fdtitle }}</a></h3>
              <p class="noticeContent" v-html="item.fdcontent"></p>
              <router-link class="btn-link" :to="{ path:'/notice/detail', query:{id: item.pknotice}}">
                Read More
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="button-box text-center wow fadeInUp">
            <router-link :to="'/notice'" class="main-btn arrow-btn">View More News</router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PageBanner from "@/components/PageBanner";
import store from "@/store/store";
import {onMounted, ref} from "vue";
import axios from "axios";
import imgHolder from "@/components/ImgHolder";
// import Isotope from "isotope-layout";
export default {
  name: "Home",
  components: {
    PageBanner,
    imgHolder,
  },
  setup() {
    const pageInfo = store.getters.getPageInfo;
    const loading = ref(false);
    /*portfolio*/
    const baseURL = process.env.VUE_APP_IMAGE_URL;
    const resultList = ref([]);
    const data = ref([
      {
        fd_code : "PORTFOLIO_CATEGORY",
        fd_en_nm : "ALL",
        fd_etc : "포트폴리오 카테고리",
        fd_nm : "전체",
        fd_sort : 0,
        fd_use_yn : "Y",
        pk_code : 0,
        fkcate : "*",
        isActive: true
      }
    ])

    let iso

    axios.get('/api/common/codeList').then((res) => {
      res.data.cateList.forEach(item => {
        item.isActive=false
        data.value.push(item)
      })
    })

    axios.get('/api/portfolio/list', {params: {pageIndex: 1, pageUnit : 3, fkcate : 0}}).then((res) => {
      resultList.value = res.data.resultList
    })


    const selectCate = (event, index, cate) => {
      data.value.forEach((item) => {item.isActive = false})
      data.value[index].isActive = true;

      axios.get('/api/portfolio/list', {params: {pageIndex: 1, pageUnit : 3, fkcate : cate }})
        .then((res) => {
          resultList.value = res.data.resultList
        })
    }

    /*contact us*/
    let contents = ref('')

    axios.get('/api/common/getAboutUs').then((res) => {
      contents.value = res.data.view;
    })

    const vname = ref('');
    const vemail = ref('');
    const vphone = ref('');
    const vmessage = ref('');

    const sendMessage = () => {
      if(vname.value == null || vname.value == ''){
        alert('이름을 입력해주세요.');
        return false;
      }
      if(vemail.value == null || vemail.value == ''){
        alert('이메일을 입력해주세요.');
        return false;
      }
      const re	= new RegExp("^[a-z0-9_+.-]+@([a-z0-9-]+\.)+[a-z0-9]{2,4}$", "g");
      if(!re.test(vemail.value)){
        alert("이메일 형식이 맞지 않습니다.");
        return false;
      }
      if(vphone.value == null || vphone.value == ''){
        alert('핸드폰 번호를 입력해주세요.');
        return false;
      }
      if(vmessage.value == null || vmessage.value == ''){
        alert('내용을 입력해주세요.');
        return false;
      }
      if(vphone.value.length > 13 || vphone.value.length < 11){
        alert('핸드폰 번호를 다시 입력해주세요.');
        return false;
      }
      if(vmessage.value.length > 300){
        alert('내용을 다시 입력해주세요.');
        return false;
      }
      axios.get('/api/contact/proc', {params: {
          fdname: vname.value,
          fdemail: vemail.value,
          fdphone: vphone.value,
          fdmessage: vmessage.value
        }}).then((res) => {
        if(res.data.result == 'S'){
          alert('등록되었습니다.');
          location.reload();
        }else{
          alert('데이터 전송 중 에러가 발생하였습니다.');
        }
      }).catch((error) => {
        alert('데이터 전송 중 에러가 발생하였습니다.');
      })
    }

    /*statics*/

    let cnt = ref('')

    axios.get('/api/common/getProjectCnt').then((res) => {
      cnt.value = res.data.view;
    })


    /*notice*/
    const noticeList = ref([]);

    axios.get('/api/notice/list', {params: {pageIndex: 1, pageUnit : 3}}).then((res) => {
      noticeList.value = res.data.resultList
    })


    onMounted(() => {
      // setTimeout(() => {
      //   iso = new Isotope('.portfolio-grid', {
      //     itemSelector: '.portfolio-column',
      //     layoutMode: 'masonry',
      //     // layoutMode: 'fitRows'
      //   });
      // }, 500)
    })

    return {
      pageInfo,
      data,
      selectCate,
      resultList,
      baseURL,
      contents,
      sendMessage,
      vname,
      vemail,
      vphone,
      vmessage,
      noticeList,
      cnt,
      loading,
    };
  },
}
</script>

<style scoped>

.noticeContent {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

</style>
