<template>
  <PageBanner :pageInfo="pageInfo"></PageBanner>

  <section class="contact-info-v1 pt-130">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="section-title mb-60">
            <span class="sub-title st-one">Get In Touch</span>
            <h2>Have Any Project on
              Mind? Work Together</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-7">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="information-item info-item-one mb-30 wow fadeInUp" data-wow-delay=".15s">
                <div class="icon">
                  <i class="far fa-map-marker-alt"></i>
                </div>
                <div class="info">
                  <h4>Locations</h4>
                  <p>{{contents.fdlocation}}</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="information-item info-item-two mb-30 wow fadeInUp" data-wow-delay=".20s">
                <div class="icon">
                  <i class="far fa-envelope-open-text"></i>
                </div>
                <div class="info">
                  <h4>Email Address</h4>
                  <p><a href="mailto;help@g-oduk.com">{{contents.fdemail}}</a></p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="information-item info-item-three mb-30 wow fadeInUp" data-wow-delay=".25s">
                <div class="icon">
                  <i class="far fa-phone"></i>
                </div>
                <div class="info">
                  <h4>Phone  & Fax Number</h4>
                  <p><a href="tel:07033440099">P : {{contents.fdphone}}</a></p>
                  <p><a href="javascript:;">F : {{contents.fdfax}}</a></p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="information-item info-item-four mb-30 wow fadeInUp" data-wow-delay=".30s">
                <div class="icon">
                  <i class="far fa-clock"></i>
                </div>
                <div class="info">
                  <h4>Working Hours</h4>
                  <p><span>Monday - Friday</span><span>{{contents.fdbusinesshours}}</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="map-box mb-30">
            <iframe :src="contents.fdiframe" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--====== Start Contact section ======-->
  <section class="contact-area contact-area-v3 pt-130 pb-130">
    <div class="container-1350">
      <div class="contact-wrapper light-gray-bg pt-80 pb-80 wow fadeInUp">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-7">
              <div class="section-title text-center mb-55">
                <span class="sub-title st-one">Message Us</span>
                <h2>Get Any Consultations ?
                  Contact With Us</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="contact-form">
                <form>
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="form_group">
                        <input type="text" class="form_control" placeholder="Full Name" id="name" name="name" v-model="vname" maxlength="8">
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form_group">
                        <input type="email" class="form_control" placeholder="Email Address " id="email" name="email" v-model="vemail"  maxlength="50">
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form_group">
                        <input type="text" class="form_control" placeholder="Phone Number" id="phone" v-model="vphone" name="phone">
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form_group">
                        <textarea class="form_control" placeholder="Write Message" id="message" v-model="vmessage"  name="message"></textarea>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form_group text-center">
                        <button class="main-btn" @click.prevent="sendMessage">Send Us Message</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PageBanner from "@/components/PageBanner";
import store from "@/store/store";
import axios from "axios";
import {ref} from "vue";

export default {
  name: "Contact",
  components: {
    PageBanner,
  },

  setup() {
    const pageInfo = store.getters.getPageInfo;

    let contents = ref('')

    axios.get('/api/common/getAboutUs').then((res) => {
      contents.value = res.data.view;
    })

    const vname = ref('');
    const vemail = ref('');
    const vphone = ref('');
    const vmessage = ref('');

    const sendMessage = () => {
      if(vname.value == null || vname.value == ''){
        alert('이름을 입력해주세요.');
        return false;
      }
      if(vemail.value == null || vemail.value == ''){
        alert('이메일을 입력해주세요.');
        return false;
      }
      const re	= new RegExp("^[a-z0-9_+.-]+@([a-z0-9-]+\.)+[a-z0-9]{2,4}$", "g");
      if(!re.test(vemail.value)){
        alert("이메일 형식이 맞지 않습니다.");
        return false;
      }
      if(vphone.value == null || vphone.value == ''){
        alert('핸드폰 번호를 입력해주세요.');
        return false;
      }
      if(vmessage.value == null || vmessage.value == ''){
        alert('내용을 입력해주세요.');
        return false;
      }
      if(vphone.value.length > 13 || vphone.value.length < 11){
        alert('핸드폰 번호를 다시 입력해주세요.');
        return false;
      }
      if(vmessage.value.length > 300){
        alert('내용을 다시 입력해주세요.');
        return false;
      }
      axios.get('/api/contact/proc', {params: {
          fdname: vname.value,
          fdemail: vemail.value,
          fdphone: vphone.value,
          fdmessage: vmessage.value
        }}).then((res) => {
        if(res.data.result == 'S'){
            alert('등록되었습니다.');
            location.reload();
          }else{
            alert('데이터 전송 중 에러가 발생하였습니다.');
          }
      }).catch((error) => {
        alert('데이터 전송 중 에러가 발생하였습니다.');
      })
    }

    return {
      pageInfo,
      contents,
      sendMessage,
      vname,
      vemail,
      vphone,
      vmessage,
    };
  },
}
</script>

<style scoped>

</style>
