<template>
  <section class="about-area about-area-v1 position-relative pt-130" id="about">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-12">
          <div class="text-wrapper mb-50 wow fadeInRight">
            <div class="section-title mb-15">
              <span class="sub-title st-one">About Us</span>
              <h2>We’re Creative Agency Since 2017</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="process-area process-area-v1 pt-90 pb-85">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7">
          <div class="section-title text-center mb-55 wow fadeInUp">
            <!--<span class="sub-title st-one">Process</span>-->
            <h5>다양한 경험과 노하우로 최적의 서비스를 제작하여 드립니다.</h5>
            <p>Professional Development to provide solutions</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 ">
          <div class="process-item text-center mb-40 wow fadeInUp" data-wow-delay=".15s">
            <div class="icon">
              <i class="fi fi-rr-microchip" style="font-size: 90px;"></i>
              <!--<img src="assets/images/icon/icon-7.png" alt="">-->
            </div>
            <div class="text">
              <h4>Technology</h4>
              <p>다양한 경험을 바탕으로 맞춤형 소프트웨어를 개발합니다.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 ">
          <div class="process-item text-center mb-40 wow fadeInUp" data-wow-delay=".20s">
            <div class="icon">
              <i class="fi fi-rr-people-arrows-left-right" style="font-size: 90px;"></i>
              <!--<img src="assets/images/icon/icon-8.png" alt="">-->
            </div>
            <div class="text">
              <h4>Consulting</h4>
              <p>사용자에 기대에 맞는 최적의 방법을 제공해드립니다.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 ">
          <div class="process-item text-center mb-40 wow fadeInUp" data-wow-delay=".25s">
            <div class="icon">
              <i class="fi fi-rr-display-code" style="font-size: 90px;"></i>
              <!--<img src="assets/images/icon/icon-9.png" alt="">-->
            </div>
            <div class="text">
              <h4>Development</h4>
              <p>변화하는 트렌드에 맞추어 모든 디바이스를 만족하는 경쟁력있는 웹,모바일, APP을 만들고 있습니다.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Build",
  components: {
  },

  setup() {

    return {
    }
  }
}
</script>

<style scoped>

</style>
