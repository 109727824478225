<template>
  <section class="about-area about-area-v1 position-relative pt-130" id="about">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-12">
          <div class="text-wrapper mb-50 wow fadeInRight">
            <div class="section-title mb-15">
              <span class="sub-title st-one">About Us</span>
              <h2>We’re Creative Agency Since 2017</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="process-area process-area-v1 pt-90 pb-85">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7">
          <div class="section-title text-center mb-55 wow fadeInUp">
            <!--<span class="sub-title st-one">Process</span>-->
            <h5>빠르게 변화되고 있는 IT환경 속에서 솔루션의 안정적인 기능과 시스템 장애의 신속한 대처, 더불어 전문인력의 기술지원도 필수적입니다.</h5>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 ">
          <div class="process-item text-center mb-40 wow fadeInUp" data-wow-delay=".15s">
            <div class="icon">
              <i class="fi fi-rr-shield-check" style="font-size: 90px;"></i>
              <!--<img src="assets/images/icon/icon-7.png" alt="">-->
            </div>
            <div class="text">
              <h4>Stability</h4>
              <p>사이트는 만드는것도 중요하지만 유지가 더 중요합니다.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 ">
          <div class="process-item text-center mb-40 wow fadeInUp" data-wow-delay=".20s">
            <div class="icon">
              <i class="fi fi-rr-bullseye-arrow" style="font-size: 90px;"></i>
              <!--<img src="assets/images/icon/icon-8.png" alt="">-->
            </div>
            <div class="text">
              <h4>Marketing</h4>
              <p>마케팅의 성공에 이면에는 안정적인 서비스가 기반이 되어야 합니다.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 ">
          <div class="process-item text-center mb-40 wow fadeInUp" data-wow-delay=".25s">
            <div class="icon">
              <i class="fi fi-rr-hr-person" style="font-size: 90px;"></i>
              <!--<img src="assets/images/icon/icon-9.png" alt="">-->
            </div>
            <div class="text">
              <h4>Total</h4>
              <p>안정적인 서비스를 위해 서버부터 프로그램까지 토탈케어 서비스를 제공합니다.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Operate",
  components: {
  },

  setup() {

    return {
    }
  }
}
</script>

<style scoped>

</style>
