<template>
  <section class="service-area service-area-v1 bototm-minus-200 position-relative z-2 pt-210 pb-210">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="section-title text-center mb-55 wow fadeInUp">
            <span class="sub-title st-one">Services</span>
            <h5>쉽고 편하게 운영 가능한 온라인 교육 플랫폼.</h5>
            <h5>다양한 LMS 구축 노하우를 바탕으로 최적의 솔루션을 제공합니다.</h5>
            <!--<p>쉽고 편하게 운영 가능한 온라인 교육 플랫폼. 다양한 LMS 구축 노하우를 바탕으로 최적의 솔루션을 제공합니다.</p>-->
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".2s">
            <div class="icon">
              <i class="fi fi-rr-customize"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">고객 맞춤형 이러닝솔루션</a></h5>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".4s">
            <div class="icon">
              <i class="fi fi-rr-crm-computer"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">고객사 ERP, CRM 등 연동 가능</a></h5>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".3s">
            <div class="icon">
              <i class="fi fi-rr-lock"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">최고의 보안, 기술적 안정성</a></h5>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".4s">
            <div class="icon">
              <i class="fi fi-rr-workflow-setting-alt"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">온/오프라인 교육 통합관리</a></h5>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".2s">
            <div class="icon">
              <i class="fi fi-rr-marketplace"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">구축형 LMS</a></h5>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".4s">
            <div class="icon">
              <i class="fi fi-rr-payment-pos"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">다양한 PG시스템</a></h5>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".4s">
            <div class="icon">
              <i class="fi fi-rr-globe"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">멀티 플렛폼</a></h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section><!--====== End Service Section ======-->
</template>

<script>
export default {
  name: "Learning",
  components: {
  },

  setup() {

    return {
    }
  }
}
</script>

<style scoped>

</style>
