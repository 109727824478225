<template>
  <!--====== Start Service Section ======-->
  <section class="service-area service-area-v1 bototm-minus-200 position-relative z-2 pt-210 pb-210">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="section-title text-center mb-55 wow fadeInUp">
            <span class="sub-title st-one">Services</span>
            <h5>HTML5기반 웹 채팅솔루션으로 PC,태블릿,스마트폰 모든 디바이스 최신 브라우저 환경을 동시 지원하는 실시간 웹 채팅 솔루션입니다.</h5>
            <!--<p>쉽고 편하게 운영 가능한 온라인 교육 플랫폼. 다양한 LMS 구축 노하우를 바탕으로 최적의 솔루션을 제공합니다.</p>-->
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".4s">
            <div class="icon">
              <i class="fi fi-rr-site-browser"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">HTML5기반 웹 체팅 솔루션
              </a></h5>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".3s">
            <div class="icon">
              <i class="fi fi-rr-quiz"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">다양한 형태의 솔루션제공
              </a></h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".4s">
            <div class="icon">
              <i class="fi fi-rr-users"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">회원연동
              </a></h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".2s">
            <div class="icon">
              <i class="fi fi-rr-lock"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">강력한 보안 기능
              </a></h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".2s">
            <div class="icon">
              <i class="fi fi-rr-laptop-mobile"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">멀티디바이스, 브라우저 지원
              </a></h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".2s">
            <div class="icon">
              <i class="fi fi-rr-people-poll"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">최대 1000명 동시접속
              </a></h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="button-box text-center mt-30 wow fadeInUp">
          <a href="javascript:void(0)" class="main-btn arrow-btn bordered-btn btn-blue" @click="openChatWindow">채팅 솔루션 Demo</a>
        </div>
      </div>
    </div>
    <div class="row" style="display: none;">
      <div class="col-lg-12">
        iframe 데모보기.
      </div>
    </div>
  </section><!--====== End Service Section ======-->
</template>

<script>
import gdChatClient from "@/chat/chatClient";

export default {
  name: "Chat",
  components: {
  },

  setup() {
    const openChatWindow = () => {
            gdChatClient.Client({});
      };

    return {
      openChatWindow,
    }
  }
}
</script>

<style scoped>

</style>
