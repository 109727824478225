<template>
  <!--====== Start Service Section ======-->
  <section class="service-area service-area-v1 bototm-minus-200 position-relative z-2 pt-210 pb-210">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="section-title text-center mb-55 wow fadeInUp">
            <span class="sub-title st-one">Services</span>
            <h5>별도의 장비 없이 스마트폰 하나로 손쉽게 오디오 라이브 방송을 개설하여 진행하고 라이브커머스 연동을 통해 수익을 창출하는 기능을 제공합니다.</h5>
            <!--<p>쉽고 편하게 운영 가능한 온라인 교육 플랫폼. 다양한 LMS 구축 노하우를 바탕으로 최적의 솔루션을 제공합니다.</p>-->
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".4s">
            <div class="icon">
              <i class="fi fi-rr-store-alt"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">라이브커머스 솔루션 연동
              </a></h5>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".3s">
            <div class="icon">
              <i class="fi fi-rr-video-camera-alt"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">온라인 비디오 플랫폼 서비스
              </a></h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".4s">
            <div class="icon">
              <i class="fi fi-rr-live-alt"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">실시간 방송에 최적화
              </a></h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".2s">
            <div class="icon">
              <i class="fi fi-rr-customize"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">네이버스테이션, 와우자등 고객 맞춤형 연동</a></h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".2s">
            <div class="icon">
              <i class="fi fi-rr-replace"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">브랜드 맞춤형 커스터마이징 제공
              </a></h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section><!--====== End Service Section ======-->
</template>

<script>
export default {
  name: "Live",
  components: {
  },

  setup() {

    return {
    }
  }
}
</script>

<style scoped>

</style>
