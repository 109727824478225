<template>
  <footer class="footer-area page-footer light-gray-bg">
    <div class="container">
      <div class="footer-top pt-75 pb-40">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="footer-logo mb-40 wow fadeInLeft">
              <img src="/images/company/logo.png" alt="Brand Logo">
            </div>
          </div>
          <div class="col-md-6">
            <div class="text-wrapper mb-40 wow fadeInRight">
              <h3>Modern Solutionsd For
                <span class="blue-dark">Creative Agency</span></h3>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-copyright">
        <div class="row">
          <div class="col-lg-6">
            <div class="copyright-text">
              <p>&copy; 2016. All rights reserved design by G-oduk</p>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="footer-nav float-lg-right">
              <ul>
                <li><a href="services.html">Setting & Privacy </a></li>
                <li><a href="services.html">Faqs</a></li>
                <li><a href="services.html">Services</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a href="javascript:void(0);" class="back-to-top" ref="backToTopBtn" @click="scrollTop"><i class="far fa-angle-up"></i></a>
  </footer>
</template>

<script>
import {onMounted, onUnmounted, ref} from "vue";
import scrollUtil from "@/utils/scrollUtil";

export default {
  name: "Footer",
  components: {

  },
  setup() {
    const { fadeIn, fadeOut ,scrollTop } = scrollUtil();
    const backToTopBtn = ref();

    const updateScroll = () => {
      if (window.scrollY > 600) {
        fadeIn(backToTopBtn.value)
      } else {
        fadeOut(backToTopBtn.value)
      }
    }

    onMounted(() => {
      window.addEventListener('scroll', updateScroll)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', updateScroll)
    })

    return {
      backToTopBtn,
      scrollTop,
    }
  },
}
</script>


<style scoped>

</style>
