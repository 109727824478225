<template>
  <!--====== Start Service Section ======-->
  <section class="service-area service-area-v1 bototm-minus-200 position-relative z-2 pt-210 pb-210">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="section-title text-center mb-55 wow fadeInUp">
            <span class="sub-title st-one">Services</span>
            <h5>간편한 설치, 쉬운 주문! 간편하게 주문부터 결제까지 한 번에 해결하여 인권비 절감하세요.</h5>
            <!--<p>쉽고 편하게 운영 가능한 온라인 교육 플랫폼. 다양한 LMS 구축 노하우를 바탕으로 최적의 솔루션을 제공합니다.</p>-->
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".4s">
            <div class="icon">
              <i class="fi fi-rr-seller"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">업체별 다양한 스킨 테마
              </a></h5>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".3s">
            <div class="icon">
              <i class="fi fi-rr-function-process"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">마스터 태블릿 추가 기능
              </a></h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".4s">
            <div class="icon">
              <i class="fi fi-rr-language"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">다국어 지원
              </a></h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".2s">
            <div class="icon">
              <i class="fi fi-rr-reservation-table"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">테이블에서 바로 결제</a></h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".2s">
            <div class="icon">
              <i class="fi fi-rr-replace"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">브랜드 맞춤형 커스터마이징
              </a></h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".2s">
            <div class="icon">
              <i class="fi fi-rr-no-fee"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">결제 수수료 없는 월정액제.
              </a></h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section><!--====== End Service Section ======-->
</template>

<script>
export default {
  name: "Menu",
  components: {
  },

  setup() {

    return {
    }
  }
}
</script>

<style scoped>

</style>
