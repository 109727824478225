<template>
  <Transition>
    <div class="preloader" ref="preLoader" v-if="!isLoaded">
      <div class="loader">
        <div class="pre-shadow"></div>
        <div class="pre-box"></div>
      </div>
    </div>
  </Transition>
</template>

<script>
import {onMounted, ref} from "vue";

export default {
  name: "Preloader",
  components: {

  },
  setup() {
    let isLoaded = ref(false);

    onMounted(() => {
      setTimeout(() => {
        isLoaded.value = true
      }, 500)
    });

    return {
      isLoaded,
    };
  },
}
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
