<template>
  <div class="img-holder">
    <img :src="imgSrc" alt="Img">
<!--    <a :href="imgSrc" class="portfolio-hover img-popup" @click.prevent="popupImg($event)">-->
<!--      <div class="hover-content">-->
<!--        <i class="far fa-plus"></i>-->
<!--      </div>-->
<!--    </a>-->
  </div>
</template>

<script>

export default {
  name: "ImgHolder",
  components: {

  },
  props: {
    imgSrc: {
      type: String,
      default: '',
    }
  },
  setup() {
    const popupImg = (event) => {
      const target = event.target;
    }

    return {
      popupImg,
    }
  }
}
</script>

<style scoped>

</style>
