<template>
  <div :key="$route.fullPath">
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/layout/Header";
import Footer from "@/components/layout/Footer";

export default {
  name: 'App',
  components: {
    Footer,
    Header
  },
  setup() {
  },
}
</script>

<style>
#app {

}
</style>
