<template>
  <Preloader></Preloader>
  <header class="theme-header transparent-header">
    <!-- header Navigation -->
    <div class="header-navigation navigation-style-v1" :class="{ sticky: isScrolling, 'breakpoint-on': toggleForm }">
      <div class="nav-overlay" :class="{ 'active': navOverlay }" @click="navMenuClose"></div>
      <div class="container-fluid">
        <div class="primary-menu">
          <div class="site-branding">
            <router-link to="/solution/learning" class="brand-logo"><img src="/images/company/logo.png" alt="Site Logo"></router-link>
          </div>
          <div class="nav-menu" :class="{ 'menu-on': navOverlay }">
            <!-- Navbar Close -->
            <div class="navbar-close" @click="navMenuClose"><i class="far fa-times"></i></div>
            <!-- Nav Search -->
<!--            <div class="nav-search">-->
<!--              <form>-->
<!--                <div class="form_group">-->
<!--                  <input type="email" class="form_control" placeholder="Search Here" name="email" required>-->
<!--                  <button class="search-btn"><i class="fas fa-search"></i></button>-->
<!--                </div>-->
<!--              </form>-->
<!--            </div>-->
            <!-- Main Menu -->
            <nav class="main-menu">
              <ul>
                <li class="menu-item" :class="{'has-children': menu.child === 'Y'}" v-for="(menu, index) in menuList" :key="index">
                  <router-link :to="menu.path" class="nav-link" :class="{active: $route.path.search(menu.name.toLowerCase()) >= 0}">{{ menu.name }}</router-link>
                  <ul class="sub-menu" v-if="menu.child === 'Y'" >
                    <li v-for="(childMenu, index2) in childMenuList" :key="index2">
                      <router-link class="active" :to="childMenu.path" v-if="childMenu.parentName === menu.name">
                        {{childMenu.name}}
                      </router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
          <div class="header-right-nav">
            <ul>
              <li class="bar-item"><a href="javascript:;" @click="sidebarOpen"><img src="/images/dot.png" alt="dot"></a></li>
              <li class="navbar-toggle-btn">
                <div class="navbar-toggler" :class="{ 'active': navOverlay }" @click="navMenuOpen">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <SideBar
        :panelOn="panelOn"
        @closePanel="closePanel"
    ></SideBar>
  </header>

  <PageBanner :pageInfo="pageInfo"></PageBanner>
</template>

<script>
import {onMounted, onUnmounted, ref, watch} from "vue";
import SideBar from "@/components/layout/SideBar";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import Preloader from "@/components/Preloader";
import PageBanner from "@/components/PageBanner";
import mainTheme from "@/js/theme";

export default {
  name: "Header",
  components: {
    SideBar,
    Preloader,
    PageBanner,
  },
  setup() {
    const route = useRoute();

    const menuList = [
        // { name: 'Home', path: '/', level: 1 },
        // { name: 'About Us', path: '/about_us', level: 1 },
        { name: 'SOLUTION', path: '/solution/learning', level: 1, child: 'Y', bannerImg: '/images/solution/chemist-5919765_640.png' },
        { name: 'SERVICE', path: '/service/build', level: 1, child: 'Y', bannerImg: '/images/solution/programming-6119584_640.png' },
        { name: 'Portfolio', path: '/portfolio', level: 1, child: 'N', bannerImg:'/images/company/computer-2170392_1280.jpg' },
        // { name: 'Notice', path: '/notice', level: 1 },
        // { name: 'Notice Detail', path: '/notice/detail', level: 1 },
        { name: 'Contact', path: '/contact', level: 1, child: 'N', bannerImg:'' },
    ];
    const childMenuList = [
        { name: 'GD E-learning', path: '/solution/learning', level: 2, parentName: 'SOLUTION', bannerImg: '/images/solution/chemist-5919765_640.png' },
        { name: 'GD Live', path: '/solution/live', level: 2, parentName: 'SOLUTION', bannerImg: '/images/solution/laptop-2298286_1280.png' },
        { name: 'GD Online EMR', path: '/solution/emr', level: 2, parentName: 'SOLUTION', bannerImg: '/images/solution/medical-5459661_1280.png' },
        { name: 'GD Menu', path: '/solution/menu', level: 2, parentName: 'SOLUTION', bannerImg: '/images/solution/plate-303475_640.png' },
        { name: 'GD Chat', path: '/solution/chat', level: 2, parentName: 'SOLUTION', bannerImg: '/images/solution/social-media-3846597_640.png' },
        { name: 'GD Delivery', path: '/solution/delivery', level: 2, parentName: 'SOLUTION', bannerImg: '/images/solution/delivery-4728949_1280.png' },
        { name: '서비스 구축', path: '/service/build', level: 2, parentName: 'SERVICE', bannerImg: '/images/solution/programming-6119584_640.png' },
        { name: '유지 보수', path: '/service/operate', level: 2, parentName: 'SERVICE', bannerImg: '/images/solution/development-4536630_640.png' },
    ];
    const pageInfo = ref(false)

    const currentPage = menuList.filter((item) => route.path === item.path)[0]
    if (currentPage !== undefined && currentPage.child === 'N') {
      pageInfo.value = currentPage
    } else {
      pageInfo.value = childMenuList.filter((item) => route.path === item.path)[0]
    }

    const isScrolling = ref(false);
    const toggleForm = ref(window.innerWidth <= 1199);
    const panelOn = ref(false);
    const navOverlay = ref(false);

    const sidebarOpen = () => {
      panelOn.value = true;
    }

    const closePanel = () => {
      panelOn.value = false;
    }

    const updateScroll = () => {
      let scrollTop = window.scrollY;
      isScrolling.value = scrollTop >= 100;
    }

    const toggleHeader = () => {
      toggleForm.value = window.innerWidth <= 1199
    }

    const navMenuOpen = () => {
      navOverlay.value = true;
    }

    const navMenuClose = () => {
      navOverlay.value = false;
    }

    onMounted(() => {
      window.addEventListener('scroll', updateScroll);
      window.addEventListener('resize', toggleHeader);

      mainTheme(window.jQuery)
      mainTheme(window.jQuery).mainMenu()
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', updateScroll);
      window.removeEventListener('resize', toggleHeader);
    })

    return {
      menuList,
      childMenuList,
      panelOn,
      sidebarOpen,
      closePanel,
      isScrolling,
      toggleForm,
      navMenuOpen,
      navOverlay,
      navMenuClose,
      pageInfo,
    };
  },
}
</script>

<style scoped>

</style>
