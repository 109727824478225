import { createStore } from "vuex";

const store = createStore({
    state:{
        // store 변수객체
        name: "",
        pageInfo: {
            name: '',
            path: '',
            level: '',
            bannerImg: ''
        },
    },

    getters: {
        // store에 저장된 변수를 get 할 수 있는 메소드
        getName(state) {
            return state.name;
        },
        getPageInfo(state) {
            return state.pageInfo;
        },
    },

    mutations: {
        // 동기적으로 store 변수를 조작하는 setter
        setName(state, name) {
            state.name = name;
        },
        setPageInfo(state, pageInfo) {
            state.pageInfo = pageInfo;
        },
    },

    actions: {
        // 비동기적으로 store 변수를 조작하는 setter
        setNameActions(commit, name) {
            commit('setName', name)
        },

    }
})

export default store;
