<template>
  <!--====== Start Portfolio Section ======-->
  <section class="portfolio-area portfolio-area-v1 light-gray-bg pt-210 pb-130">
    <div class="container" v-if="!loading">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="section-title mb-45 text-center wow fadeInUp">
            <span class="sub-title st-one">Latest Work</span>
            <h2>Professional Experience</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="portfolio-filter-button text-center mb-40 wow fadeInUp">
            <ul class="filter-btn mb-30">
              <li
                  :class="{ active: item.isActive }"
                  :data-filter="item.fkcate"
                  v-for="(item, index) in data"
                  :key="index"
                  :id="item.pk_code"
                  @click="selectCate($event, index, item.pk_code)"
              >{{ item.fd_en_nm }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row portfolio-grid">
        <div class="col-lg-4 col-md-6 col-sm-12 portfolio-column" :class="item.fkcatenmen" v-for="(item, index) in resultList" :key="index">
          <div class="portfolio-item portfolio-style-one mb-50 wow fadeInUp" data-wow-delay=".10s">
            <img-holder :img-src="baseURL + item.fdthimgpath" class="img-holder">
            </img-holder>
            <div class="text">
              <h3 class="title">{{ item.fdtitle }}</h3>
              <p class="cat-link">{{ item.fdsubtitle }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="button-box text-center wow fadeInUp">
            <a href="javascript:;" class="main-btn arrow-btn" @click="loadMore" v-if="loaded">View More</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--====== End Portfolio Section ======-->
</template>

<script>
import Isotope from "isotope-layout"
import store from "@/store/store";
import {nextTick, onMounted, onUpdated, ref} from "vue";
import ImgHolder from "@/components/ImgHolder";
import axios from "axios";

export default {
  name: "Portfolio",
  components: {
    ImgHolder,
  },
  setup() {
    const baseURL = process.env.VUE_APP_IMAGE_URL;
    const data = ref([
        {
          fd_code : "PORTFOLIO_CATEGORY",
          fd_en_nm : "ALL",
          fd_etc : "포트폴리오 카테고리",
          fd_nm : "전체",
          fd_sort : 0,
          fd_use_yn : "Y",
          pk_code : 0,
          fkcate : "*",
          isActive: true
        }
    ])

    const loaded = ref(true);
    const loading = ref(false);
    const filterValue = ref('*');
    const resultList = ref([]);
    let paginationInfo = '';
    let currentCate = 0;
    let iso;

    axios.get('/api/common/codeList').then((res) => {
      res.data.cateList.forEach(item => {
        item.isActive=false
        data.value.push(item)
      })
    })

    axios.get('/api/portfolio/list', {params: {pageIndex: 1, pageUnit : 9, fkcate : 0}}).then((res) => {
      resultList.value = res.data.resultList
      paginationInfo = res.data.paginationInfo
      if(paginationInfo.currentPageNo == paginationInfo.lastPageNo){
        loaded.value = false
      }
    })

    const selectCate = async (event, index, cate) => {
      if (currentCate === cate) {
        return;
      }
      data.value.forEach((item) => {item.isActive = false})
      data.value[index].isActive = true;
      currentCate = cate;
      filterValue.value = event.target.getAttribute('data-filter');

      axios.get('/api/portfolio/list', {params: {pageIndex: 1, pageUnit : 9, fkcate : currentCate}}).then((res) => {
        resultList.value = res.data.resultList
        paginationInfo = res.data.paginationInfo
        if(paginationInfo.currentPageNo == paginationInfo.lastPageNo){
          loaded.value = false
        } else {
          loaded.value = true
        }
      })

      setTimeout(() => {
        iso.reloadItems()
        iso.arrange({filter: filterValue.value})
      }, 100)
    }

    const loadMore = async () => {
      await axios.get('/api/portfolio/list', {params: {pageIndex: paginationInfo.currentPageNo+1, pageUnit : 9, fkcate: currentCate}})
          .then((res) => {
            res.data.resultList.forEach((item) => {resultList.value.push(item)})
            paginationInfo = res.data.paginationInfo
            if(paginationInfo.currentPageNo == paginationInfo.lastPageNo){
              loaded.value = false
            }
          })

      setTimeout(() => {
        iso.reloadItems()
        iso.arrange({filter: filterValue.value})
      }, 100)
    };


    onMounted(() => {
      setTimeout(() => {
        iso = new Isotope('.portfolio-grid', {
          itemSelector: '.portfolio-column',
          layoutMode: 'masonry',
          // layoutMode: 'fitRows'
        });
      }, 500)

    })

    return{
      data,
      selectCate,
      resultList,
      baseURL,
      loadMore,
      loaded,
      loading,
      filterValue,
      currentCate,
    }
  }
}
</script>

<style scoped>

</style>
