<template>
  <section class="hero-banner-v1 position-relative" v-if="pageInfo.name === 'Home'">
    <div class="bg-one"></div>
    <div class="bg-two"></div>
    <div class="hero-img hero-img-one scene"><span data-depth=".5"><img src="/images/company/business-4271251_245.png" class="wow fadeInLeft" alt="hero image"></span></div>
    <div class="hero-img hero-img-two scene"><span data-depth=".2"><img src="/images/company/hands-typing-2654109_640.jpg" class="wow fadeInLeft" alt="hero image"></span></div>
    <div class="hero-img hero-img-three scene"><span data-depth=".3"><img src="/images/shape/circle-logo-1.png" class="wow fadeInLeft" alt="hero image"></span></div>
    <div class="shape shape-one scene"><span data-depth="1"><img src="/images/shape/shape-1.png" alt="shape"></span></div>
    <div class="shape shape-two scene"><span data-depth="2"><img src="/images/shape/shape-2.png" alt="shape"></span></div>
    <div class="shape shape-three scene"><span data-depth="3"><img src="/images/shape/shape-3.png" alt="shape"></span></div>
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-12">
          <div class="hero-content">
            <h1 class="wow fadeInUp" data-wow-delay=".5s">Brand, Design
              & Development
              Agency</h1>
            <p class="wow fadeInDown" data-wow-delay="1s">Web & Mobile app development
              bring your ideas to life</p>
            <router-link to="/contact" class="main-btn arrow-btn wow fadeInUp" data-wow-delay=".5s">Let’s Talk Us</router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="page-banner bg_cover position-relative z-1" v-if="pageInfo.name !== 'Home'">
    <div class="shape shape-one scene"><span data-depth="1"><img src="/images/shape/shape-1.png" alt=""></span></div>
    <div class="shape shape-two scene"><span data-depth="2"><img src="/images/shape/shape-2.png" alt=""></span></div>
    <div class="shape shape-three scene"><span data-depth="3"><img src="/images/shape/shape-3.png" alt=""></span></div>
    <div class="shape shape-four scene"><span data-depth="4"><img src="/images/shape/shape-2.png" alt=""></span></div>
    <div class="container">
      <div class="row" :class="{ 'align-items-center': pageInfo.name === 'About Us','justify-content-center': (pageInfo.name === 'Notice') || (pageInfo.name === 'Contact') || (pageInfo.name === 'Notice Detail') }">
        <div class="col-lg-6">
          <div class="page-title" :class="{ 'text-center': (pageInfo.name === 'Notice') || (pageInfo.name === 'Contact') || (pageInfo.name === 'Notice Detail') }">
            <h1>{{ pageInfo.name }}</h1>
            <ul class="breadcrumbs-link">
<!--              <li><router-link to="/">Home</router-link></li>-->
<!--              <li class="active">{{ pageInfo.name }}</li>-->
            </ul>
          </div>
        </div>
        <div class="col-lg-6" v-if="pageInfo.bannerImg != ''">
          <div class="page-banner-img">
            <img :src="pageInfo.bannerImg" alt="">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Parallax from "@/utils/parallax.min"
import {onMounted, ref} from "vue";

export default {
  name: "PageBanner",
  components: {

  },
  props: {
    pageInfo: {
      type: Object,
      default: {}
    },
  },
  setup() {
    const target = document.getElementsByClassName('scene');

    onMounted(() => {
      for (let i = 0; i < target.length; i++){
        new Parallax(target.item(i))
      }
    })

    return {

    };
  },
}
</script>

<style scoped>

</style>
