<template>
  <!--====== Start Service Section ======-->
  <section class="service-area service-area-v1 bototm-minus-200 position-relative z-2 pt-210 pb-210">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="section-title text-center mb-55 wow fadeInUp">
            <span class="sub-title st-one">Services</span>
            <h5>지도를 기반으로 관리하는 배달 앱 솔루션입니다. </h5>
            <!--<p>쉽고 편하게 운영 가능한 온라인 교육 플랫폼. 다양한 LMS 구축 노하우를 바탕으로 최적의 솔루션을 제공합니다.</p>-->
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".4s">
            <div class="icon">
              <i class="fi fi-rr-store-alt"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">다양한 매장 관리

              </a></h5>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".3s">
            <div class="icon">
              <i class="fi fi-rr-function-process"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">마스터에서 손쉬운 상품관리

              </a></h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".4s">
            <div class="icon">
              <i class="fi fi-rr-laptop-mobile"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">멀티플렛폼지원

              </a></h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".2s">
            <div class="icon">
              <i class="fi fi-rr-message-arrow-up-right"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">브랜드 자체 앱으로 인지도 향상 효과와 브랜드 가치 향상
              </a></h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".2s">
            <div class="icon">
              <i class="fi fi-rr-stamp"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">스탬프 적립 및 브랜드 프로모션으로 단골 고객 유치 및 증가
              </a></h5>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="service-item service-style-three mb-40 wow fadeInUp" data-wow-delay=".2s">
            <div class="icon">
              <i class="fi fi-rr-bells"></i>
            </div>
            <div class="text">
              <h5 class="title"><a href="service-details.html">카카오 알림톡 연계 서비스로 주문시 정확한 알림 가능
              </a></h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section><!--====== End Service Section ======-->
</template>

<script>
export default {
  name: "Delivery",
  components: {
  },

  setup() {

    return {
    }
  }
}
</script>

<style scoped>

</style>
