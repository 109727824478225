const gdChatClient  = {

    Client: (data) =>  {
        // let iframe = $(`<iframe src="http://192.168.0.110:8055/iframe/index?channelId=${data.channelId}&chatType=${data.chatType}&companyCode=${data.companyCode}" allow="camera *;microphone *;display-capture *" frameborder="no" scrolling="no" marginwidth="0" marginheight="0" width="100%" height="100%"></iframe>`);
        // let parent = document.getElementById(data.id);
        window.open(`http://gdchat.solamt.com:8080/iframe/index?channelId=PCHGOWEPJXGN&chatType=DEMO&companyCode=GODUK`, '_blank', "width=700,height=1000");
    }

};

export default gdChatClient;
